import { LOGOUT_TIMEOUT } from "@constants/subsctibtions";

export const startLogoutTimer = (onLogout: () => void) => {
    let userLoginTimestamp = 0;
    const storageLoginTimestamp = window.localStorage.getItem('loginTimestamp');

    if (storageLoginTimestamp) {
        userLoginTimestamp = Number(storageLoginTimestamp);
    } else {
        const dateNow = Date.now().toString();
        window.localStorage.setItem('loginTimestamp', dateNow);
        userLoginTimestamp = Number(dateNow);
    }

    const checkLogoutTime = async () => {
        const timeElapsed = Date.now() - userLoginTimestamp;
        const timeRemaining = LOGOUT_TIMEOUT - timeElapsed;

        // console.error('seconds', (timeRemaining / 1000).toFixed());
        if (timeRemaining <= 0) {
            window.localStorage.removeItem('loginTimestamp');
            onLogout();
            clearInterval(interval);
        }
    };

    checkLogoutTime();

    const interval = setInterval(() => {
        checkLogoutTime();
    }, 10000);

    return interval
};
