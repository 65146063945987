export enum EvTruck {
    IntroShown = 'intro_shown',
    IntroSuccess = 'intro_success',
    EmailSuccess = 'email_success',
    SubscriptionShown = 'subscription_shown',
    ModalPaymentShown = 'modal_payment_shown',
    CardSubmit = 'card_submit',
    ApplePaySubmit = 'apple_pay_submit',
    ModalPaymentSubmit = 'modal_payment_submit',
    Purchase = 'ecommerce_purchase',
    PurchaseError = 'ecommerce_purchase_error',
    AdditionalPurchase = 'ecommerce_additional_purchase',
    AdditionalPurchaseError = 'ecommerce_additional_purchase_error',
    OffersShown = 'offers_shown',
    AccountScreenShown = 'account_product_shown',
    ResultsScreenShown = 'results_product_shown',
}
