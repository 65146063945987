import { IProfile } from '@models/webapp'
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useState
} from 'react'

export interface IUser {
    uid: string
    email: string
    displayName: string
    providerDataId?: string
    providerId: 'google.com' | 'apple.com'
    isAppleAuth: boolean
    isGoogleAuth: boolean
}

export const UserContext = createContext<null | {
    user: null | IUser
    setUser: Dispatch<SetStateAction<IUser | null>>
    profile: null | IProfile
    setProfile: React.Dispatch<React.SetStateAction<IProfile | null>>
}>(null)

UserContext.displayName = 'UserContext'

function UserProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<null | IUser>(null)
    const [profile, setProfile] = useState<IProfile | null>(null)

    const value = {
        user,
        setUser,
        profile,
        setProfile,
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider
