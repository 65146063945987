import { cancelSubscriptionsWithParameter } from "@api/requests";
import Loader from "@components/WebApp/components/Loader";
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react";

const Unsubscribe = () => {
    const [texts, setTexts] = useState({ title: '', subTitle: '' });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const content = urlParams.get('from');

        if (content) {
            cancelSubscriptionsWithParameter({
                content: content,
            })
                .then((response) => {
                    setTexts({
                        title: 'Your subscription has been canceled',
                        subTitle: "We've successfully cancelled your subscription.",
                    });
                })
                .catch((error) => {
                    console.error('cancelSubscriptionsWithParameter error', error);
                    setTexts({
                        title: 'No subscriptions',
                        subTitle:
                            'Unfortunately there are no subscription plans attached to this mail. Check if the specified email address is correct',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F3F2F8',
            width: '100%',
            height: '100%',
            minHeight: '100vh',

        }}
    >
        <Box
            sx={{
                width: '100%',
                maxWidth: '460px',
                minHeight: '100vh',
                padding: '30px 20px 20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: 'space-between',
                textAlign: 'center',
                alignItems: 'center',
                backgroundImage: 'url(images/gradient_background.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'top center'
            }}
        >
            <Box>
                <img src="/cognifi/images/unsubscribe/Sign.svg" width={70} height={52} />
                {
                    loading ? <Loader position="center" /> :
                        <>

                            <Typography
                                sx={{
                                    marginTop: '20px',
                                    marginBottom: '10px',
                                    fontFamily: 'Avenir600',
                                    fontSize: '24px',
                                    color: '#020202',
                                }}
                            >
                                {texts.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Avenir400',
                                    fontSize: '16px',
                                    color: 'rgba(2, 2, 2, 0.50)',
                                }}
                            >
                                {texts.subTitle}
                            </Typography>
                        </>

                }
            </Box>
            <Box
                sx={{
                    paddingTop:'20px',
                    borderTop:'1px solid rgba(230, 231, 235, 1)',
                    width:'100%',
                    fontFamily:'Avenir400',
                    fontSize:'12px',
                    color: 'rgba(2, 2, 2, 0.50)',
                    '& a':{
                        color:'#6639E6'
                    }
                }}
            >
                Have a question? Reach our friendly support team <a href="mailto:support@trackbeat.app" target="_blank">here</a>
            </Box>
        </Box>
    </Box>
}
export default Unsubscribe


