import React, { useEffect } from 'react'

import { Box, Button, Link, Typography } from '@mui/material'

import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import { trackEvent } from '@api/requests'
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks'
import QuizStepBackgroundWrapper from '@components/common/QuizStepBackgroundWrapper'
import QuizContainer from '@components/common/QuizContainer'
import { signInWithApple, signInWithGoogle } from '@services/firebase'
import { isIos, isMetaInAppBrowser } from '@utils/browser'
import { APP_CONSTANTS } from '@utils/constants'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'

function Account() {
    const { userLocation } = useQuizState()
    const showAppleSignIButton = isIos()
    const { loading, error } = usePaymentState()

    useEffect(() => {
        trackEvent(`Account screen shown`, userLocation)
        wmq.track(EvTruck.AccountScreenShown)
    }, [])

    return (
        <QuizStepBackgroundWrapper>
            <QuizContainer>
                <Box
                    sx={{
                        marginTop: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <img
                        width={'70px'}
                        height={'52px'}
                        src={'svg/intro_sign.svg'}
                        alt="logo"
                    ></img>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontWeight: 600,
                            fontSize: '24px',
                            userSelect: 'none',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            color: '#020202',
                            marginTop: '30px',
                            marginBottom: '10px',
                            span: {
                                color: 'var(--primary-color)',
                            },
                        }}
                    >
                        Cognifi
                        <br />
                        <span>Successfully Purchased</span>
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            userSelect: 'none',
                            textAlign: 'center',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                        }}
                    >
                        Now create your account.
                        <br />
                        Choose one of the listed Sign In options.
                    </Typography>

                    <Box
                        sx={{
                            marginTop: '20px',
                            display: 'flex',
                            width: '100%',
                            // border: '1px solid red',
                            gap: '10px',
                            flexDirection: 'column',
                            '& button.sign-in-button': {
                                fontFamily: 'Avenir600',
                                fontSize: '16px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                borderRadius: '12px',
                                textTransform: 'none',
                                padding: '14px',
                                width: '100%',
                            },
                        }}
                    >
                        {showAppleSignIButton && (
                            <>
                                <Button
                                    disabled={loading}
                                    onClick={signInWithApple}
                                    className="sign-in-button"
                                    sx={{
                                        background: '#020202',
                                        color: 'white',
                                        '&:hover': {
                                            background: '#020202',
                                        },
                                    }}
                                >
                                    <img
                                        src={'svg/apple-logo.svg'}
                                        alt="apple"
                                    />{' '}
                                    Sign in with Apple
                                </Button>
                                {!isMetaInAppBrowser && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '20px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                background: '#E6E7EB',
                                            }}
                                        />{' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Avenir400',
                                                fontSize: '16px',
                                                lineHeight: 'normal',
                                                color: 'rgba(2, 2, 2, 0.50)',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            or
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                background: '#E6E7EB',
                                            }}
                                        />
                                    </Box>
                                )}
                            </>
                        )}

                        {!isMetaInAppBrowser && (
                            <Button
                                disabled={loading}
                                onClick={signInWithGoogle}
                                className="sign-in-button"
                                sx={{
                                    background: 'white',
                                    color: '#020202',
                                    border: '1px solid rgba(2, 2, 2, 0.10)',
                                    '&:hover': {
                                        background: 'white',
                                    },
                                }}
                            >
                                <img src={'svg/google-logo.svg'} alt="google" />
                                Sign in with Google
                            </Button>
                        )}
                    </Box>

                    {error && (
                        <Typography
                            sx={{ margin: '20px 0 0 0', color: '#E03045' }}
                        >
                            {error}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            borderTop: '1px solid #E6E7EB',
                            padding: '20px 0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '14px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                lineHeight: 'normal',
                                a: {
                                    color: 'var(--primary-color)',
                                    textDecorationColor: 'var(--primary-color)',
                                },
                            }}
                        >
                            Have a question? Reach our friendly support team{' '}
                            <Link
                                target="_blank"
                                href={`mailto:${APP_CONSTANTS.EMAIL}`}
                                className="link"
                            >
                                here
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </QuizContainer>
        </QuizStepBackgroundWrapper>
    )
}

export default Account
