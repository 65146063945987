import React, { useEffect, useMemo, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import CommonFooterItems from '@components/common/CommonFooterItems'
import QuizButton, { ButtonType } from '@components/common/QuizButton'
import { useNavigate } from 'react-router-dom'
import { getPathWithSearchParams } from '@utils/ulr'
import { Routes } from '../../../../App'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import { useWebAppState } from '@components/WebApp/WebApp'
import { FETCHING_TIMEOUT } from '@constants/subsctibtions'

function ManageSubscriptions() {
    const [isCancelSubsModalOpen, setCancelSubsModalOpen] = useState(false)
    const { subscriptionStartedAt } = useWebAppState()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const results = [
        {
            title: 'Personalized IQ certificate',
            sunTitle:
                'We’ve created a personalized certificate that is in one click from you, ready to download.',
            btnText: 'Download certificate',
            color: '#6639E6',
            onClick: () => {
                navigate(getPathWithSearchParams(Routes.WEBAPP_PROFILE))
                window.scrollTo(0, 0)
            },
        },
        {
            title: 'Detailed analysis report',
            sunTitle:
                'We’ve prepared a detailed report of your IQ test, oddering in-depth insights into your profile',
            btnText: 'View my report',
            color: '#AF5FFF',
            onClick: () => {
                navigate(getPathWithSearchParams(Routes.WEBAPP_PROFILE))
                window.scrollTo(0, 0)
            },
        },
    ]


    const isFetching = useMemo(() => {
        if (subscriptionStartedAt) {
            const timezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
            const adjustedSubsStartDate = new Date(new Date(subscriptionStartedAt).getTime() - timezoneOffsetMs);
            const differenceInMs = new Date().getTime() - adjustedSubsStartDate.getTime();
            return differenceInMs < FETCHING_TIMEOUT;
        } else {
            return false;
        }
    }, [subscriptionStartedAt]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    margin: 'auto',
                    maxWidth: '460px',
                    backgroundColor: '#fff',
                    padding: '0 20px',
                    backgroundImage:
                        'url(/cognifi/images/gradient_background.webp)',
                    backgroundSize: '100% 300px',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        '& .logo': {
                            margin: '30px auto 0',
                        },
                    }}
                >
                    <img
                        className="logo"
                        width={'151px'}
                        height={'108px'}
                        src={'/cognifi/images/unsubscribe/arrow_icon.webp'}
                        alt="logo"
                    ></img>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontSize: '24px',
                            lineHeight: 'normal',
                            color: '#020202',
                            textAlign: 'center',
                            userSelect: 'none',
                            marginTop: '30px',
                            marginBottom: '10px',
                            span: {
                                color: '#6639E6',
                            },
                        }}
                    >
                        Elevate Your <span>IQ and Mental Potential</span> with
                        our guides
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                            textAlign: 'center',
                            userSelect: 'none',
                            marginBottom: '30px',
                        }}
                    >
                        Get your IQ report and access strategies to sharpen
                        focus, memory, and problem-solving.
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'column',
                        }}
                    >
                        {results.map((el) => (
                            <Box
                                key={el.title}
                                sx={{
                                    background: '#F3F2F8',
                                    borderRadius: '20px',
                                    padding: '0 20px 20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '20px',
                                }}
                            >
                                <svg
                                    width="161"
                                    height="85"
                                    viewBox="0 0 161 85"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g mask="url(#mask0_70_7256)">
                                        <path
                                            d="M80.5 -75V85"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                        <path
                                            d="M160.5 5L0.499999 4.99999"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                        <path
                                            d="M149.781 45L11.2172 -35"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                        <path
                                            d="M120.5 74.282L40.5 -64.2821"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                        <path
                                            d="M40.5 74.282L120.5 -64.2821"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                        <path
                                            d="M11.2188 45L149.783 -35"
                                            stroke={el.color}
                                            strokeWidth="10"
                                        />
                                    </g>
                                </svg>

                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Avenir600',
                                            fontSize: '20px',
                                            lineHeight: 'normal',
                                            color: '#020202',
                                            textAlign: 'center',
                                            userSelect: 'none',
                                        }}
                                    >
                                        {el.title}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: 'Avenir400',
                                            fontSize: '16px',
                                            lineHeight: 'normal',
                                            color: '#020202',
                                            textAlign: 'center',
                                            userSelect: 'none',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {el.sunTitle}
                                    </Typography>
                                </Box>

                                <QuizButton
                                    bgColor={el.color}
                                    onClick={el.onClick}
                                    variant={ButtonType.RESULTS}
                                    text={el.btnText}
                                />
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                            width: '100%',
                            margin: '30px 0',
                        }}
                    >
                        <Typography
                            sx={{
                                width: '100%',
                                height: '1px',
                                background: '#E6E7EB',
                            }}
                        />
                        <img
                            src="/cognifi/svg/divider_decor.svg"
                            alt="decor"
                        ></img>
                        <Typography
                            sx={{
                                width: '100%',
                                height: '1px',
                                background: '#E6E7EB',
                            }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontSize: '20px',
                            lineHeight: 'normal',
                            color: '#020202',
                            textAlign: 'center',
                            userSelect: 'none',
                            marginBottom: '15px',
                        }}
                    >
                        My active plan
                    </Typography>

                    <Box
                        sx={{
                            padding: '15px',
                            borderRadius: '20px',
                            border: '1px solid rgba(35, 38, 49, 0.10)',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            fontFamily: 'Avenir600',
                            fontSize: '16px',
                            marginBottom: '15px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                            }}
                        >
                            {
                                isFetching ?
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                width: '32px',
                                                height: '32px',
                                                position: 'relative',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span className="plan_fetching_loader"></span>
                                        </Box>
                                        Fetching plans
                                    </>
                                    : <>
                                        <img
                                            width={32}
                                            height={32}
                                            src={'/cognifi/images/unsubscribe/canceled.svg'}
                                            alt="logo"
                                        />
                                        Weekly Plan
                                    </>
                            }
                        </Box>
                        <Button
                            disabled={isFetching}
                            sx={{
                                width: '132px',
                                height: '48px',
                                fontFamily: 'Avenir600',
                                color: '#E53E3E',
                                fontSize: '16px',
                                background: 'rgba(229, 62, 62, 0.10)',
                                textTransform: 'none',
                                borderRadius: '12px',
                                '&:disabled': {
                                    background: '#F3F2F8',
                                    color: '#A9A7AF'
                                }
                            }}
                            onClick={()=>setCancelSubsModalOpen(true)}
                        >
                            Cancel
                        </Button>

                    </Box>
                    <Typography
                        sx={{
                            textAlign:'center',
                            fontFamily:'Avenir400',
                            fontSize:'16px',
                            color:'rgba(2, 2, 2, 0.50)',
                            '& .red':{
                                color:'#E53E3E',
                                fontFamily:'Avenir600',
                            }
                        }}
                    >
                        By canceling your membership, <span className='red'>you’ll miss out</span> on certified IQ tests and essential improvement tips that support your growth.
                    </Typography>
                    <Box
                        sx={{
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <CommonFooterItems />
                    </Box>
                </Box>
            </Box>
            {isCancelSubsModalOpen && (
                <CancelSubscriptionModal
                    isModalOpen={isCancelSubsModalOpen}
                    setModalOpen={setCancelSubsModalOpen}
                />
            )}
        </>
    )
}

export default ManageSubscriptions
