import { Route, Routes as RoutesInner } from 'react-router-dom'
import Main from './components/Main'
import Quiz from './components/Quiz'
import Subscription from './components/Subscription'
import Privacy from './components/Docs/Privacy'
import Terms from './components/Docs/Terms'
import CookiePolicy from './components/Docs/CookiePolicy'
import { Routes } from './App'
import { useCheckAuth } from '@hooks/useCheckAuth'
import Offers from '@components/Offers/Offers'
import AccessProduct from '@components/AccessProduct'
import SignIn from '@components/SignIn/SignIn'
import WebApp from '@components/WebApp'
import { getLastPath } from '@utils/ulr'

import {
    Articles,
    Compare,
    Games,
    ManageSubscriptions,
    Profile,
    Report,
    Tests,
} from '@components/WebApp/pages'
import Unsubscribe from '@components/Unsubscribe/Unsubscribe'
import UnsubscribeWithEmail from '@components/UnsubscribeWithEmail/UnsubscribeWithEmail'

export default function Router() {
    useCheckAuth()

    return (
        <RoutesInner>
            <Route path={Routes.MAIN + '*'} element={<Main />} />
            <Route path={Routes.QUIZ} element={<Quiz />} />
            <Route path={Routes.SUBSCRIPTION} element={<Subscription />} />
            <Route path={Routes.OFFERS} element={<Offers />} />
            <Route path={Routes.ACCESS_PRODUCT} element={<AccessProduct />} />
            <Route path={Routes.SIGN_IN} element={<SignIn />} />
            <Route path={Routes.PRIVACY} element={<Privacy />} />
            <Route path={Routes.TERMS} element={<Terms />} />
            <Route path={Routes.COOKIE_POLICY} element={<CookiePolicy />} />
            <Route path={Routes.UNSUBSCRIBE} element={<Unsubscribe/>}/>
            <Route path={Routes.UNSUBSCRIBE_EMAIL} element={<UnsubscribeWithEmail/>}/>

            <Route path={Routes.WEBAPP} element={<WebApp />}>
                <Route
                    index
                    path={getLastPath(Routes.WEBAPP_TESTS)}
                    element={<Tests />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_GAMES)}
                    element={<Games />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_COMPARE)}
                    element={<Compare />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_ARTICLES)}
                    element={<Articles />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_PROFILE)}
                    element={<Profile />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_PROFILE_REPORT)}
                    element={<Report />}
                />
                <Route
                    path={getLastPath(Routes.WEBAPP_MANAGE_SUBSCRIPTIONS)}
                    element={<ManageSubscriptions />}
                />
            </Route>
        </RoutesInner>
    )
}
