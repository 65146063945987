import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../../App';
import { getRedirectAppDomain, getRedirectToTestUrl, isOnAppDomain } from '@utils/payment';

function MainHeader() {
  const navigate = useNavigate();
  const [isShowBg, setShowBg] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      const isVisible = position > 10;
      setShowBg(isVisible);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleLoginClick = () => {
    if(isOnAppDomain){
      navigate(getPathWithSearchParams(Routes.SIGN_IN));
    }else{
      const redirectUrl = `${getRedirectAppDomain()}${Routes.SIGN_IN}${window.location.search}`;
      window.open(redirectUrl, '_self');
    }
  }

  const handleStartTestClick = () => {
    if(isOnAppDomain){
      window.open(getRedirectToTestUrl(), '_self');
    }else{
      navigate(getPathWithSearchParams(Routes.QUIZ));
    }
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 99,
        left: 0,
        right: 0,
        top: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderBottom: '1px solid rgba(2, 2, 2, 0.10)',
          maxWidth: '460px',
          margin: 'auto',
          padding: '10px 20px',
          background: isShowBg ? 'rgba(255, 255, 255, 0.70)' : 'transparent',
          transition: 'all 0.5s ease',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <img width={'108px'} src={'svg/logo.svg'} alt="logo"></img>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
          }}
        >
          <Button
            onClick={handleLoginClick}
            sx={{
              backgroundColor: 'transparent',
              outline: '1px solid var(--primary-color)',
              fontFamily: 'Avenir600',
              fontSize: '14px',
              borderRadius: '8px',
              height: '36px',
              color: '#020202',
              padding: '8px 15px',
              textWrap: 'nowrap',
              textTransform: 'initial',
            }}
          >
            Log In
          </Button>
          <Button
            onClick={handleStartTestClick}
            sx={{
              backgroundColor: 'var(--primary-color)',
              color: '#fff',
              fontFamily: 'Avenir600',
              fontSize: '14px',
              borderRadius: '8px',
              height: '36px',
              padding: '8px 15px',
              textWrap: 'nowrap',
              textTransform: 'initial',
              '&:hover': {
                backgroundColor: 'var(--primary-color)',
              },
            }}
          >
            Start Test
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MainHeader;
