import { getParamByKey, filterFalsyValues } from './common'
import cookie from 'js-cookie'
import { getFacebookClickParameter } from '@utils/facebook'

export const getReferrer = () => {
    let referer: any = null

    try {
        const refererUrl = new URL(document.referrer)

        if (refererUrl.host !== document.location.host) {
            localStorage.setItem('_ref', refererUrl.toString())
        }
    } catch (e) {}

    referer = localStorage.getItem('_ref')

    return referer
}

export const getAttribution = ({
    ip,
    country,
}: {
    ip: string
    country: string
}) =>
    filterFalsyValues({
        project: 'cognifi',
        campaign_id: getParamByKey('campaign_id'),
        campaign_name: getParamByKey('campaign_name'),
        adset_id: getParamByKey('adset_id'),
        adset_name: getParamByKey('adset_name'),
        ad_id: getParamByKey('ad_id'),
        media_source: getParamByKey('media_source'),
        config: getParamByKey('config'),
        country_code: country || '',
        ad_name: getParamByKey('ad_name'),
        fbc: cookie.get('_fbc') ?? getFacebookClickParameter()._fbc,
        fbp: getParamByKey('_fbp') ?? getFacebookClickParameter()._fbp,
        referer: getReferrer(),
        event_source_url:
            getParamByKey('redirectDomain') ?? window.location.href,
        client_user_agent: navigator.userAgent,
        client_ip_address: ip,
        idfm: new URLSearchParams(window.location.search).get('_idfm') ?? localStorage.getItem('@RNMV/IDFM'),
    })

export function convertStringToBoolean(incomeString: string) {
    switch (incomeString.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true
        case 'false':
        case 'no':
        case '0':
        case null:
            return false
        default:
            return false
    }
}
