import BaseModal from "@components/WebApp/components/common/BaseModal"
import { Box, Typography } from "@mui/material"

const NotificationModal = (
    {
        isModalOpen,
        setModalOpen
    }: {
        isModalOpen: boolean
        setModalOpen: (state: boolean) => void
    }) => {
    return <BaseModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} showClose={false}>
                    <>
                <img
                    src={'/cognifi/images/unsubscribe/canceled.svg'}
                    alt="logo"
                ></img>
                <Typography
                    sx={{
                        color: '#020202',
                        fontFamily: 'Avenir600',
                        lineHeight: 'normal',
                        fontSize: '20px',
                        marginTop: '16px',
                    }}
                >
                    Check your email
                </Typography>

                <Typography
                    sx={{
                        color: 'rgba(2, 2, 2, 0.50)',
                        fontFamily: 'Avenir400',
                        lineHeight: 'normal',
                        fontSize: '16px',
                        marginTop: '4px',
                        marginBottom: '16px',
                    }}
                >
                    We have sent you an email confirming the end of the current subscription
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        '& button': {
                            border: 'none',
                            borderRadius: '12px',
                            background: '#6639E6',
                            padding: '19px 32px',
                            color: '#fff',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            fontSize: '16px',
                            width: '100%',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            WebkitTapHighlightColor: 'transparent',
                        },
                    }}
                >
                    <button
                        onClick={() => {
                            setModalOpen(false)
                        }}
                    >
                        OK
                    </button>
                </Box>
            </>
    </BaseModal>
}

export default NotificationModal