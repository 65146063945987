import { Button, DisabledButton } from "@components/WebApp/pages/Games/components/GameCard.style"
import { Box, CircularProgress, Input, Typography } from "@mui/material"
import { EMAIL_REGEX } from "@utils/constants"
import { useState } from "react"
import NotificationModal from "./components/NotificationModal"
import { cancelSubscritionViaEmail } from "@api/requests"

const UnsubscribeWithEmail = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [isModalOpen, setModalOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = () => {
        if (isLoading) return
        const isValid = EMAIL_REGEX.test(email.trim())

        if (!isValid) {
            setError('Please enter a valid email')
            return
        }

        setLoading(true)
        cancelSubscritionViaEmail({ email })
            .then(async (response) => {
                if ('error' in response) {
                    setError(response.error)
                } else {
                    if (response.success) {
                        setModalOpen(true)
                    } else {
                        setError('Something went wrong')
                    }
                }
            })
            .catch(async (error) => {
                console.log('cancelSubscription error', error)
                let parsedErrorMessage = ''
                try {
                    const errorJson = await error.json()
                    if ('error' in errorJson) {
                        parsedErrorMessage = errorJson.error
                    }
                } catch (err) {
                    console.log(err)
                }
                const errorMessage =
                    parsedErrorMessage !== '' ? parsedErrorMessage : error
                setError(
                    typeof errorMessage === 'object'
                        ? 'Something went wrong'
                        : JSON.stringify(errorMessage)
                )
            })
            .finally(() => {
                setLoading(false)
            })


    }

    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F3F2F8',
            width: '100%',
            height: '100%',
            minHeight: '100vh',
        }}
    >
        <Box
            sx={{
                width: '100%',
                maxWidth: '460px',
                minHeight: '100vh',
                padding: '30px 20px 20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: 'space-between',
                textAlign: 'center',
                alignItems: 'center',
                backgroundImage: 'url(images/gradient_background.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'top center'
            }}
        >
            <Box>
                <img src="/cognifi/images/unsubscribe/Sign.svg" width={70} height={52} />
                <Typography
                    sx={{
                        marginTop: '20px',
                        fontFamily: 'Avenir500',
                        fontSize: '15px',
                        color: '#6639E6',
                    }}
                >
                    Terninate subscription
                </Typography>
                <Typography
                    sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        fontFamily: 'Avenir600',
                        fontSize: '24px',
                        color: '#020202',
                    }}
                >
                    To cancel your subscription - please use the email used to purchase
                </Typography>
                <Box
                    sx={{
                        marginBottom: '20px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            height: '60px',
                            padding: '0 20px',
                            gap: '15px',
                            borderRadius: '12px',
                            border: '1px solid rgba(2, 2, 2, 0.10)',
                            background: '#FFF',
                        }}
                    >
                        <img src="/cognifi/images/unsubscribe/mail-01.svg" />
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            disableUnderline
                            fullWidth
                            value={email}
                            onChange={
                                (e) => {
                                    setError('')
                                    setEmail(e.target.value)
                                }
                            }
                            inputProps={{
                                sx: {
                                    outline: 'none',
                                    fontFamily: 'Avenir400',
                                    fontSize: '16px',
                                    color: '#020202',
                                    '&::placeholder': {
                                        color: 'rgba(169, 167, 175, 1)',
                                        opacity: 1,
                                    }

                                }
                            }}
                        />
                    </Box>
                    {!!error && <Typography
                        sx={{
                            marginTop: '10px',
                            fontFamily: 'Avenir400',
                            color: '#E53E3E',
                            fontSize: '14px'
                        }}
                    >
                        {error}
                    </Typography>}
                </Box>

                {
                    email ?
                        <Button
                            onClick={handleSubmit}
                        >
                            {
                                isLoading ? <CircularProgress
                                    size={16}
                                    sx={{
                                        color: '#A9A7AF',
                                    }}
                                />
                                    : "Cancel subscription"
                            }

                        </Button> :
                        <DisabledButton>

                            Cancel subscription
                        </DisabledButton>
                }
            </Box>
            <Box
                sx={{
                    paddingTop: '20px',
                    borderTop: '1px solid rgba(230, 231, 235, 1)',
                    width: '100%',
                    fontFamily: 'Avenir400',
                    fontSize: '12px',
                    color: 'rgba(2, 2, 2, 0.50)',
                    '& a': {
                        color: '#6639E6'
                    }
                }}
            >
                Have a question? Reach our friendly support team <a href="mailto:support@trackbeat.app" target="_blank">here</a>
            </Box>
        </Box>
        <NotificationModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
        />
    </Box>
}

export default UnsubscribeWithEmail